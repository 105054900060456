
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  
  .alert-enter {
    animation: fadeIn 0.3s forwards;
  }
  
  .alert-exit {
    animation: fadeOut 0.3s forwards;
  }
  