.full-height-container {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  min-height: 100vh; /* Full viewport height */
  background-color: rgba(17, 34, 51, 0.316);
}

.signin-container {
  direction: rtl;
  width: 100%;
  max-width: 400px;
  margin: auto;
  padding: 50px;
  text-align: center;
  background-color: #123; /* Replace with the actual background color */
  color: #fff; /* Replace with the actual text color */
}

.signin-container h1 {
  margin-bottom: 20px;
}

.signin-container p {
  margin-bottom: 30px;
}

.signin-container input[type="email"],
.signin-container input[type="password"] {
  width: 100%;
  padding: 15px;
  margin: 10px 0;
  border: none;
  background-color: #234; /* Replace with the actual input background color */
  color: #fff;
}

.checkbox-label {
  margin-right: 3px; 
  margin-top: 10px;
}

.forgot-password-link {
  margin-right: 210px; 
}

.signin-container input[type="checkbox"] {
  margin-bottom: 20px;
  margin-top: 10px;
}

.form-container {
  display: flex;
  flex-direction: column;
  align-items: center; /* This will center the button horizontally */
}

.signin-container button {
  width: 100%;
  padding: 15px;
  border: none;
  background-color: rgb(39, 222, 129);
  color: #123; /* Replace with the actual button text color */
  cursor: pointer;
}

.signin-container a {
  color: rgb(39, 222, 129); /* Replace with the actual link color */
  text-decoration: none;
}

.signin-container a:hover {
  text-decoration: underline;
}
