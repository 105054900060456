@keyframes highlightVisible {
  0% { border-color: transparent; }
  50% { border-color: red; }
  100% { border-color: transparent; }
}

.highlight-temp {
  border: 5px solid transparent; /* Start and end with transparent */
  animation: none; /* No animation initially */
}

.highlight-temp.fade-in {
  animation: highlightVisible 2s forwards; /* Complete cycle from transparent to red and back to transparent */
}


.highlight-button {
  border: 5px solid transparent; /* Maintain consistent border size but transparent */
  color: inherit;
  background: none;
  padding: inherit;
  font: inherit;
  cursor: pointer;
}
