@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.gradient-text {
  background: linear-gradient(270deg, #ff6ec4, #7873f5, #4ddcd0);
  background-size: 600% 600%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: gradient 5s ease infinite;
}

.message {
  opacity: 1;
  transition: opacity 0.5s linear;
}

.fade-out {
  opacity: 0;
}

.fade-in {
  opacity: 1;
  animation: fadeIn 0.5s ease-in;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.loader-container {
  opacity: 0;
  animation: fadeIn 1s forwards;
}
